import { isObject } from '../lib/nodash';
import Mustache from 'mustache';

function useHandlebarsTemplate(tmpl = '', data) {
  if (isObject(tmpl)) {
    return tmpl;
  }
  return Mustache.render(tmpl, data);
}

export default useHandlebarsTemplate;
