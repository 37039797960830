import gql from 'graphql-tag';

export const CREATE_PRICE_RULE_DISCOUNT_CODE = gql`
  mutation CreatePriceRuleDiscountCode(
    $priceRuleId: String!
    $codePrefix: String
  ) {
    createPriceRuleDiscountCode(
      priceRuleId: $priceRuleId
      codePrefix: $codePrefix
    ) {
      type
      code
      value {
        percentage
        dollar
      }
      errors
    }
  }
`;
