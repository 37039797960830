import React, { useEffect, useCallback, useState, memo } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get, head, startCase } from '../../lib/nodash';
import { Box, Text } from 'grommet';
import { useMutation, useLazyQuery } from '@apollo/client';
import { parse } from 'query-string';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import loadable from '@loadable/component';

import Loading from '../Loading';
import useRichText from '../useRichText';
import useHandlebarsTemplate from '../useHandlebarsTemplate';
import { useStoryblokState } from '../../lib/storyblok';
import isBlank from '../../lib/isBlank';
import { CREATE_PRICE_RULE_DISCOUNT_CODE } from '../../queries/discountCodeQueries';
import { CHECK_SEGMENT_PRESENCE } from '../../queries/mailingListQueries';
import useApplyDiscountCode from '../Cart/useApplyDiscountCode';
import ResponsiveDisplay from '../ResponsiveDisplay';
import { identify } from '../../lib/analytics';

import { backdropApiClient } from '../../gatsby-theme-apollo/client';
import {
  setDiscountCode,
  setDiscountCodeAmount,
  addCampaignCode,
} from '../../state/cart/cartSlice';
import { track } from '../../lib/analytics';
import { CAMPAIGN_LANDED } from '../../lib/analytics/segmentActions';

const ImgixImage = loadable(() => import('../ImgixImage'));
const FormCampaignEmailCapture = loadable(() =>
  import('./FormCampaignEmailCapture')
);

const expiredError = `We're sorry, you are not eligible for this discount. You either have already activated a similar discount or it has expired.`;

const ModalCampaign = ({ story, utmSource }) => {
  const location = useLocation();
  const urlParams = parse(location.search);
  const [emailFormSubmitted, setEmailFormSubmitted] = useState(false);
  const [subscriberCountOverLimit, setSubscriberCountOverLimit] =
    useState(false);
  const [error, setError] = useState(false);
  const campaign = useStoryblokState(story);
  const dispatch = useDispatch();
  const { applyDiscountCode } = useApplyDiscountCode();
  const content = get('content', campaign) || {};
  const {
    image,
    media,
    body,
    generateCode,
    discountCodeTitle,
    priceRuleId,
    codePrefix,
    mailingListId,
    mailingListCta,
    mailingListSuccessBody,
    emailRequiredForDiscount,
    mailingListSubscriptionThreshold,
    mailingListSuccessBodyUnder,
    mailingListSuccessBodyOver,
    mailingListSubscriptionRequirement,
    segmentId,
    segmentRequirement,
    title,
  } = content;
  const discountCode = useSelector((state) =>
    get('code', get(campaign.slug, get('cart.campaignDiscounts', state)))
  );

  const discountCodeAmount = useSelector((state) =>
    get('amount', get(campaign.slug, get('cart.campaignDiscounts', state)))
  );
  const richText = useRichText(body, {
    percentOff: discountCodeAmount,
    discountCode: discountCode,
  });
  const signupSuccessContent = useRichText(mailingListSuccessBody, {
    percentOff: discountCodeAmount,
    discountCode: discountCode,
  });
  const underLimitContent = useRichText(mailingListSuccessBodyUnder, {
    percentOff: discountCodeAmount,
    discountCode: discountCode,
  });
  const overLimitContent = useRichText(mailingListSuccessBodyOver, {
    percentOff: discountCodeAmount,
    discountCode: discountCode,
  });
  const interpolatedDiscountCodeTitle = useHandlebarsTemplate(
    discountCodeTitle,
    { title: startCase(utmSource) }
  );
  // Queries
  const [checkSegmentPresence, { loading: segmentLoading }] = useLazyQuery(
    CHECK_SEGMENT_PRESENCE
  );
  // Mutations
  const [createPriceRuleDiscountCode, { loading }] = useMutation(
    CREATE_PRICE_RULE_DISCOUNT_CODE,
    {
      client: backdropApiClient,
      onCompleted: (d) => {
        handleCodeCreated(d, 'createPriceRuleDiscountCode');
      },
    }
  );

  const hasSubscriberThreshold =
    mailingListSubscriptionThreshold &&
    parseInt(mailingListSubscriptionThreshold) > 0;

  const passesSegmentRequirement = useCallback(
    async (email, segment, requirement) => {
      if (!requirement || requirement === 'any') {
        return true;
      } else {
        const response = await checkSegmentPresence({
          variables: { email, segmentId: segment },
        });
        const inSegment = response?.checkSegmentPresence?.inSegment;
        return requirement === 'included'
          ? inSegment
          : requirement === 'not_included'
          ? !inSegment
          : false;
      }
    },
    [checkSegmentPresence]
  );

  // Callbacks
  const handleEmailCaptured = useCallback(
    async (values, { subscriberCount, wasAlreadySubscribed }) => {
      if (emailRequiredForDiscount) {
        const meetsSegmentRequirement = passesSegmentRequirement(
          values.email,
          segmentId,
          segmentRequirement
        );

        const meetsSubscriptionRequirement =
          mailingListSubscriptionRequirement === 'subscribed'
            ? wasAlreadySubscribed
            : mailingListSubscriptionRequirement === 'not_subscribed'
            ? !wasAlreadySubscribed
            : true;

        if (meetsSubscriptionRequirement && meetsSegmentRequirement) {
          if (priceRuleId) {
            await createPriceRuleDiscountCode({
              variables: {
                priceRuleId: priceRuleId,
                codePrefix: codePrefix,
              },
            });
          }
          setEmailFormSubmitted(true);
        } else {
          // Set error
          setError(expiredError);
        }
      } else {
        setEmailFormSubmitted(true);
      }

      if (
        hasSubscriberThreshold &&
        subscriberCount > parseInt(mailingListSubscriptionThreshold)
      )
        setSubscriberCountOverLimit(true);
    },
    [
      priceRuleId,
      codePrefix,
      setEmailFormSubmitted,
      emailRequiredForDiscount,
      interpolatedDiscountCodeTitle,
      passesSegmentRequirement,
      segmentId,
      segmentRequirement,
    ]
  );

  const handleCodeCreated = useCallback(
    async (d, path) => {
      const base = get(path, d);
      if (get('errors', base)) {
        setError(head(get('errors', base)));
        return;
      }

      dispatch(
        addCampaignCode({
          campaign: campaign.slug,
          amount: get('value.percentage', base)
            ? get('value.percentage', base)
            : get('value.dollar', base),
          code: get('code', base),
        })
      );
      dispatch(setDiscountCode(get('code', base)));
      dispatch(
        setDiscountCodeAmount(
          get('value.percentage', base)
            ? `${get('value.percentage', base)}%`
            : `$${get('value.dollar', base)}`
        )
      );

      await applyDiscountCode(get('code', base), {
        campaignName: title,
      });
      setEmailFormSubmitted(true);
    },
    [addCampaignCode, setDiscountCodeAmount, applyDiscountCode, campaign]
  );

  const segmentRequirementCheck = useCallback(async () => {
    const meetsRequirement = await passesSegmentRequirement(
      urlParams.email,
      segmentId,
      segmentRequirement
    );
    if (!meetsRequirement) {
      setError(expiredError);
    } else {
      createPriceRuleDiscountCode({
        variables: {
          priceRuleId: priceRuleId,
          codePrefix: codePrefix,
        },
      });
    }
  }, [
    codePrefix,
    priceRuleId,
    urlParams,
    segmentId,
    segmentRequirement,
    createPriceRuleDiscountCode,
  ]);

  // Effects
  useEffect(() => {
    track(CAMPAIGN_LANDED, { campaign: utmSource });
  }, [utmSource]);

  useEffect(() => {
    if (urlParams.email) {
      identify({ email: urlParams.email });
    }
    if (
      urlParams.email &&
      segmentId &&
      segmentRequirement !== 'any' &&
      priceRuleId
    ) {
      if (discountCode) {
        setEmailFormSubmitted(true);
      } else {
        segmentRequirementCheck();
      }
    }

    if (
      generateCode &&
      priceRuleId &&
      !discountCode &&
      !emailRequiredForDiscount
    ) {
      if (location.search.includes('_storyblok')) {
        dispatch(
          addCampaignCode({
            campaign: campaign.slug,
            amount: 'XX',
            code: 'TESTCODE',
          })
        );
        dispatch(setDiscountCode('TESTCODE'));
        dispatch(setDiscountCodeAmount(`XX%`));
      } else {
        createPriceRuleDiscountCode({
          variables: {
            priceRuleId,
            codePrefix,
          },
        });
      }
    }
  }, [
    generateCode,
    priceRuleId,
    discountCode,
    interpolatedDiscountCodeTitle,
    emailRequiredForDiscount,
  ]);

  return (
    <Box width={{ max: 'large', min: '100%' }}>
      <Box direction="row">
        {media && media.length > 0 ? (
          <ResponsiveDisplay mobile={false} desktop={true} flex>
            <StoryblokComponent blok={media[0]} />
          </ResponsiveDisplay>
        ) : (
          <>
            {image && (
              <ResponsiveDisplay mobile={false} desktop={true} flex>
                <ImgixImage
                  src={image.filename}
                  srcSetOptions={{ q: 75, auto: ['format', 'compress'] }}
                  alt={image.alt}
                  title={image.title}
                  fit="cover"
                  fill
                  sizes="25vw"
                />
              </ResponsiveDisplay>
            )}
          </>
        )}
        <Box flex pad="large">
          {loading || segmentLoading ? (
            <Loading />
          ) : (
            <Box>
              {error ? (
                <Text color="status-error">{error}</Text>
              ) : (
                <Box gap="medium">
                  {!(
                    emailFormSubmitted &&
                    (emailRequiredForDiscount || hasSubscriberThreshold)
                  ) && <Box>{richText}</Box>}
                  <Box>
                    {(!isBlank(mailingListId) || !isBlank(segmentId)) && (
                      <Box>
                        {emailFormSubmitted ? (
                          <Box>
                            {hasSubscriberThreshold &&
                              subscriberCountOverLimit &&
                              overLimitContent}
                            {hasSubscriberThreshold &&
                              !subscriberCountOverLimit &&
                              underLimitContent}
                            {!hasSubscriberThreshold && signupSuccessContent}
                          </Box>
                        ) : (
                          <FormCampaignEmailCapture
                            listId={mailingListId}
                            onSuccess={handleEmailCaptured}
                            buttonLabel={mailingListCta}
                            properties={
                              discountCode
                                ? {
                                    $discount_code: discountCode,
                                  }
                                : {}
                            }
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ModalCampaign.propTypes = {
  story: PropTypes.object,
  utmSource: PropTypes.string,
  mailingListSubscriptionThreshold: PropTypes.string,
  mailingListSuccessBodyOver: PropTypes.object,
  mailingListSuccessBodyUnder: PropTypes.object,
};

export default memo(ModalCampaign);
