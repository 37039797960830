import gql from 'graphql-tag';

export const SUBSCRIBE_TO_MAILING_LIST = gql`
  mutation SubscribeToMailingList(
    $email: String!
    $mailing_list_id: String
    $properties: JSON
  ) {
    createMailingListSubscription(
      input: {
        email: $email
        listId: $mailing_list_id
        properties: $properties
      }
    ) {
      message
      listId
      subscriberCount
      wasAlreadySubscribed
    }
  }
`;

export const CHECK_SEGMENT_PRESENCE = gql`
  query CheckKlaviyoSegmentPresence($email: String!, $segmentId: String!) {
    checkSegmentPresence(input: { email: $email, segmentId: $segmentId }) {
      email
      segmentId
      inSegment
    }
  }
`;
